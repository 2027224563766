<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header d-flex">
          <h3 class="card-title">Templates</h3>
          <button
            class="ms-auto btn btn-outline btn-sm"
            v-b-modal.new-template-modal
          >
            New Template
          </button>
        </div>
        <div v-if="loaded">
          <div class="row" v-if="templates.length > 0">
            <div class="col-8">
              <div class="table-responsive">
                <table
                  class="table card-table table-vcenter text-nowrap datatable"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="template in templates" :key="template.id">
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler me-1 icon-tabler-file-text"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                          <path
                            d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"
                          ></path>
                          <line x1="9" y1="9" x2="10" y2="9"></line>
                          <line x1="9" y1="13" x2="15" y2="13"></line>
                          <line x1="9" y1="17" x2="15" y2="17"></line>
                        </svg>
                        <router-link
                          :to="`/reputation/template/${template.private_key}`"
                        >
                          {{ template.name }}
                        </router-link>
                      </td>
                      <td>
                        {{ template.type }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-4">
              <div class="empty">
                <div class="empty-img">
                  <img
                    src="@/assets/illustrations/undraw_Completed_steps.svg"
                    alt=""
                    height="128"
                  />
                </div>

                <p class="empty-title">Put your templates to work</p>

                <p class="empty-subtitle text-muted">
                  Workflows send your templates to customers on a schedule to
                  encourage them to leave reviews after a purchase.
                </p>
                <div class="empty-action">
                  <router-link
                    :to="`/reputation/workflows`"
                    class="btn btn-primary"
                  >
                    Workflows
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="empty" v-else>
            <div class="empty-img">
              <img
                src="@/assets/illustrations/undraw_Add_files.svg"
                alt=""
                height="128"
              />
            </div>
            <p class="empty-title">No templates yet</p>
            <p class="empty-subtitle text-muted">
              Create a custom template to use in your workflow to request
              customer reviews.
            </p>
            <div class="empty-action">
              <button v-b-modal.new-template-modal class="btn btn-primary">
                Create a template
              </button>
            </div>
          </div>
        </div>
        <new-template-modal
          @saved-template="reloadTemplates"
        ></new-template-modal>
      </div>
    </div>
  </div>
</template>

<script>
import ReputationManagementService from "../../services/ReputationManagementService";
import NewTemplateModal from "./Template/NewTemplateModal.vue";

export default {
  components: { NewTemplateModal },
  name: "reputation.templates",
  data: () => {
    return {
      loaded: false,
      templates: [],
    };
  },
  methods: {
    async reloadTemplates() {
      this.templates = await ReputationManagementService.getTemplates(
        this.$auth.activeCompany
      );
      this.loaded = true;
    },
  },
  async mounted() {
    await this.reloadTemplates();
  },
};
</script>
