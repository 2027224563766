<template>
  <b-modal id="new-template-modal" size="lg">
    <template #modal-header="{ close }">
      <h5 class="modal-title">Add Template</h5>
      <button type="button" class="btn-close" @click.prevent="close()"></button>
    </template>

    <div class="modal-content">
      <div class="row">
        <div class="col-lg-8">
          <div class="mb-3">
            <label for="name" class="form-label">Name</label>
            <input
              id="name"
              type="text"
              :class="[
                'form-control',
                $v.template.name.$error ? 'is-invalid' : '',
              ]"
              v-model="$v.template.name.$model"
              autocomplete="off"
            />

            <div class="invalid-feedback" v-if="!$v.template.name.required">
              A template name is required.
            </div>
            <div class="invalid-feedback" v-if="!$v.template.name.minLength">
              Template name must be longer than 5 characters.
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="mb-3">
            <label for="type" class="form-label">Type</label>
            <select
              id="type"
              :class="[
                'form-select',
                $v.template.type.$error ? 'is-invalid' : '',
              ]"
              v-model="$v.template.type.$model"
            >
              <option value="text" selected="">Text</option>
              <option value="text/html">HTML</option>
            </select>
            <div class="invalid-feedback">A template name is required.</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <label for="body" class="form-label"> Template body </label>
            <textarea
              id="body"
              :class="[
                'form-control',
                $v.template.template.$error ? 'is-invalid' : '',
              ]"
              rows="3"
              v-model="$v.template.template.$model"
            ></textarea>
            <div class="invalid-feedback" v-if="!$v.template.template.required">
              Template is required.
            </div>
            <div
              class="invalid-feedback"
              v-if="!$v.template.template.minLength"
            >
              Template must be longer than 5 characters.
            </div>
            <div class="form-text">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-bulb"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7"
                ></path>
                <path
                  d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3"
                ></path>
                <line x1="9.7" y1="17" x2="14.3" y2="17"></line>
              </svg>
              Add merge fields to your template using
              <code v-pre>{{ field }}</code>
              Avaliable fields: <code>profile</code>, <code>company</code>,
              <code>leave_review</code>, <code>unsubscribe</code>.
            </div>
          </div>
        </div>
      </div>
    </div>

    <template #modal-footer="{ ok, cancel }">
      <a
        href="#"
        @click.prevent="cancel()"
        class="btn btn-link link-secondary"
        data-bs-dismiss="modal"
      >
        Cancel
      </a>
      <a
        href="#"
        :disabled="$v.$invalid"
        @click.prevent="saveTemplate(ok)"
        v-if="!saving"
        :class="[
          'btn',
          'btn-primary',
          'ms-auto',
          $v.$invalid ? 'disabled' : '',
        ]"
      >
        <!-- Download SVG icon from http://tabler-icons.io/i/plus -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
        Create new template
      </a>
      <a
        href="#"
        :class="['btn', 'btn-primary', 'ms-auto', 'disabled']"
        @click.prevent
        v-else
      >
        <span
          class="spinner-border spinner-border-sm me-2"
          role="status"
        ></span>
        Saving
      </a>
    </template>
  </b-modal>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import ReputationManagementService from "../../../services/ReputationManagementService";

export default {
  name: "reputation.template.newtemplatemodal",
  data: () => {
    return {
      saving: false,
      template: {
        name: "",
        type: ["text", "text/html"],
        template: "",
      },
    };
  },
  methods: {
    reset() {
      this.$v.$reset();
      this.saving = false;
      this.$set(this, "template", {
        name: "",
        type: ["text", "text/html"],
        template: "",
      });
    },
    async saveTemplate(ok) {
      if (!this.$v.$invalid) {
        this.saving = true;
        try {
          await ReputationManagementService.saveTemplate(
            this.$auth.activeCompany,
            this.template
          );
          this.$emit("saved-template");
          this.reset();
          ok();
        } catch (e) {
          this.saving = false;
        }
      }
    },
  },
  validations: {
    template: {
      name: {
        required,
        minLength: minLength(5),
      },
      type: {
        required,
      },
      template: {
        required,
        minLength: minLength(5),
      },
    },
  },
};
</script>
