var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"new-template-modal","size":"lg"},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('h5',{staticClass:"modal-title"},[_vm._v("Add Template")]),_c('button',{staticClass:"btn-close",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return close()}}})]}},{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('a',{staticClass:"btn btn-link link-secondary",attrs:{"href":"#","data-bs-dismiss":"modal"},on:{"click":function($event){$event.preventDefault();return cancel()}}},[_vm._v(" Cancel ")]),(!_vm.saving)?_c('a',{class:[
        'btn',
        'btn-primary',
        'ms-auto',
        _vm.$v.$invalid ? 'disabled' : '' ],attrs:{"href":"#","disabled":_vm.$v.$invalid},on:{"click":function($event){$event.preventDefault();return _vm.saveTemplate(ok)}}},[_c('svg',{staticClass:"icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","stroke-width":"2","stroke":"currentColor","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('path',{attrs:{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}}),_c('line',{attrs:{"x1":"12","y1":"5","x2":"12","y2":"19"}}),_c('line',{attrs:{"x1":"5","y1":"12","x2":"19","y2":"12"}})]),_vm._v(" Create new template ")]):_c('a',{class:['btn', 'btn-primary', 'ms-auto', 'disabled'],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('span',{staticClass:"spinner-border spinner-border-sm me-2",attrs:{"role":"status"}}),_vm._v(" Saving ")])]}}])},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"name"}},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.template.name.$model),expression:"$v.template.name.$model"}],class:[
              'form-control',
              _vm.$v.template.name.$error ? 'is-invalid' : '' ],attrs:{"id":"name","type":"text","autocomplete":"off"},domProps:{"value":(_vm.$v.template.name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.template.name, "$model", $event.target.value)}}}),(!_vm.$v.template.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" A template name is required. ")]):_vm._e(),(!_vm.$v.template.name.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Template name must be longer than 5 characters. ")]):_vm._e()])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"type"}},[_vm._v("Type")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.template.type.$model),expression:"$v.template.type.$model"}],class:[
              'form-select',
              _vm.$v.template.type.$error ? 'is-invalid' : '' ],attrs:{"id":"type"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$v.template.type, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"text","selected":""}},[_vm._v("Text")]),_c('option',{attrs:{"value":"text/html"}},[_vm._v("HTML")])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v("A template name is required.")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',[_c('label',{staticClass:"form-label",attrs:{"for":"body"}},[_vm._v(" Template body ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.template.template.$model),expression:"$v.template.template.$model"}],class:[
              'form-control',
              _vm.$v.template.template.$error ? 'is-invalid' : '' ],attrs:{"id":"body","rows":"3"},domProps:{"value":(_vm.$v.template.template.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.template.template, "$model", $event.target.value)}}}),(!_vm.$v.template.template.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Template is required. ")]):_vm._e(),(!_vm.$v.template.template.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Template must be longer than 5 characters. ")]):_vm._e(),_c('div',{staticClass:"form-text"},[_c('svg',{staticClass:"icon icon-tabler icon-tabler-bulb",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","stroke-width":"1","stroke":"currentColor","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('path',{attrs:{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7"}}),_c('path',{attrs:{"d":"M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3"}}),_c('line',{attrs:{"x1":"9.7","y1":"17","x2":"14.3","y2":"17"}})]),_vm._v(" Add merge fields to your template using "),_c('code',{pre:true},[_vm._v("{{ field }}")]),_vm._v(" Avaliable fields: "),_c('code',[_vm._v("profile")]),_vm._v(", "),_c('code',[_vm._v("company")]),_vm._v(", "),_c('code',[_vm._v("leave_review")]),_vm._v(", "),_c('code',[_vm._v("unsubscribe")]),_vm._v(". ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }